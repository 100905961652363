<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="LBLYEAR"
            name="measureYear"
            type="year"
            default="today"
            v-model="searchParam.measureYear"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="에너지 사용량 목록"
          tableId="processManual"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="true"
          :usePaging="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-air-report-manage',
  data() {
    return {
      col1Items: [
        { code: '1', codeName: '고정연소' },
        { code: '2', codeName: '이동연소' },
      ],
      col2Items: [
        { code: '1', codeName: '공정시설' },
        { code: '2', codeName: '일반보일러실' },
        { code: '3', codeName: '기타' },
      ],
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
            fix: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
            fix: true,
          },
          {
            name: 'col3',
            field: 'col3',
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
            fix: true,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '배출유형',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
            fix: true,
          },
          {
            name: 'col6',
            field: 'col6',
            label: '배출시설',
            align: 'left',
            sortable: true,
            style: 'width: 200px',
            type: 'link',
            fix: true,
          },
          {
            name: 'col0',
            field: 'col0',
            label: '활동데이터',
            align: 'center',
            child: [
              {
                name: 'col7',
                field: 'col7',
                label: '활동중분류',
                align: 'center',
                sortable: true,
                style: 'width: 100px',
              },
              {
                name: 'col8',
                field: 'col8',
                label: '활동자료명',
                align: 'center',
                sortable: true,
                style: 'width: 100px',
              },
              {
                name: 'col9',
                field: 'col9',
                label: 'LBLUNIT',
                align: 'center',
                sortable: true,
                style: 'width: 60px',
              },
              {
                name: 'col10',
                field: 'col10',
                label: '활동량',
                align: 'center',
                sortable: true,
                style: 'width: 100px',
              },
            ],
          },
          // {
          //   name: 'col20',
          //   field: 'col20',
          //   label: '매개변수',
          //   align: 'center',
          //   child: [
          //     {
          //       name: 'col21',
          //       field: 'col21',
          //       label: '적용Tier',
          //       align: 'center',
          //       sortable: true,
          //       style: 'width: 100px',
          //     },
          //     {
          //       name: 'col22',
          //       field: 'col22',
          //       label: '연간사용량',
          //       align: 'center',
          //       sortable: true,
          //       style: 'width: 100px',
          //     },
          //     {
          //       name: 'col23',
          //       field: 'col23',
          //       label: 'TJ',
          //       type: 'cost',
          //       sortable: true,
          //       style: 'width: 100px',
          //     },
          //     {
          //       name: 'col24',
          //       field: 'col24',
          //       label: 'TOE',
          //       type: 'cost',
          //       sortable: true,
          //       style: 'width: 100px',
          //     },
          //   ]
          // },
          // {
          //   name: 'col25',
          //   field: 'col25',
          //   label: '배출계수',
          //   child: [
          //     {
          //       name: 'col26',
          //       field: 'col26',
          //       label: 'TCO2/TJ,MWH',
          //       type: 'cost',
          //       sortable: true,
          //       style: 'width: 120px',
          //     },
          //     {
          //       name: 'col27',
          //       field: 'col27',
          //       label: 'kgCH4/TJ,MWH',
          //       type: 'cost',
          //       sortable: true,
          //       style: 'width: 120px',
          //     },
          //     {
          //       name: 'col28',
          //       field: 'col28',
          //       label: 'kgN20/TJ,MWH',
          //       type: 'cost',
          //       sortable: true,
          //       style: 'width: 120px',
          //     },
          //     {
          //       name: 'col29',
          //       field: 'col29',
          //       label: 'tCO2eq/TK,MWH',
          //       type: 'cost',
          //       sortable: true,
          //       style: 'width: 120px',
          //     },
          //   ],
          // },
          {
            name: 'col30',
            field: 'col30',
            label: '총발열량(GJ/단위)',
            type: 'cost',
            sortable: true,
            style: 'width: 120px',
          },
          // {
          //   name: 'col31',
          //   field: 'col31',
          //   label: '산화율',
          //   type: 'cost',
          //   sortable: true,
          //   style: 'width: 120px',
          // },
          // {
          //   name: 'col32',
          //   field: 'col32',
          //   label: 'GWP',
          //   child: [
          //     {
          //       name: 'col33',
          //       field: 'col33',
          //       label: 'CO2',
          //       type: 'cost',
          //       sortable: true,
          //       style: 'width: 100px',
          //     },
          //     {
          //       name: 'col34',
          //       field: 'col34',
          //       label: 'CH4',
          //       type: 'cost',
          //       sortable: true,
          //       style: 'width: 100px',
          //     },
          //     {
          //       name: 'col35',
          //       field: 'col35',
          //       label: 'N20',
          //       type: 'cost',
          //       sortable: true,
          //       style: 'width: 100px',
          //     },
          //   ],
          // },
          {
            name: 'col40',
            field: 'col40',
            label: '에너지사용량',
            child: [
              {
                name: 'col140',
                field: 'col140',
                label: '1월',
                child: [
                  {
                    name: 'col141',
                    field: 'col141',
                    label: 'TOE',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                  {
                    name: 'col142',
                    field: 'col142',
                    label: 'TJ',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                ]
              },
              {
                name: 'col240',
                field: 'col240',
                label: '2월',
                child: [
                  {
                    name: 'col241',
                    field: 'col241',
                    label: 'TOE',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                  {
                    name: 'col242',
                    field: 'col242',
                    label: 'TJ',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                ]
              },
              {
                name: 'col340',
                field: 'col340',
                label: '3월',
                child: [
                  {
                    name: 'col341',
                    field: 'col341',
                    label: 'TOE',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                  {
                    name: 'col342',
                    field: 'col342',
                    label: 'TJ',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                ]
              },
              {
                name: 'col440',
                field: 'col440',
                label: '4월',
                child: [
                  {
                    name: 'col441',
                    field: 'col441',
                    label: 'TOE',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                  {
                    name: 'col442',
                    field: 'col442',
                    label: 'TJ',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                ]
              },
              {
                name: 'col540',
                field: 'col540',
                label: '5월',
                child: [
                  {
                    name: 'col541',
                    field: 'col541',
                    label: 'TOE',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                  {
                    name: 'col542',
                    field: 'col542',
                    label: 'TJ',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                ]
              },
              {
                name: 'col640',
                field: 'col640',
                label: '6월',
                child: [
                  {
                    name: 'col641',
                    field: 'col641',
                    label: 'TOE',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                  {
                    name: 'col642',
                    field: 'col642',
                    label: 'TJ',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                ]
              },
              {
                name: 'col740',
                field: 'col740',
                label: '7월',
                child: [
                  {
                    name: 'col741',
                    field: 'col741',
                    label: 'TOE',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                  {
                    name: 'col742',
                    field: 'col742',
                    label: 'TJ',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                ]
              },
              {
                name: 'col840',
                field: 'col840',
                label: '8월',
                child: [
                  {
                    name: 'col841',
                    field: 'col841',
                    label: 'TOE',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                  {
                    name: 'col842',
                    field: 'col842',
                    label: 'TJ',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                ]
              },
              {
                name: 'col940',
                field: 'col940',
                label: '9월',
                child: [
                  {
                    name: 'col941',
                    field: 'col941',
                    label: 'TOE',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                  {
                    name: 'col942',
                    field: 'col942',
                    label: 'TJ',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                ]
              },
              {
                name: 'col1040',
                field: 'col1040',
                label: '10월',
                child: [
                  {
                    name: 'col1041',
                    field: 'col1041',
                    label: 'TOE',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                  {
                    name: 'col1042',
                    field: 'col1042',
                    label: 'TJ',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                ]
              },
              {
                name: 'col1140',
                field: 'col1140',
                label: '11월',
                child: [
                  {
                    name: 'col1141',
                    field: 'col1141',
                    label: 'TOE',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                  {
                    name: 'col1142',
                    field: 'col1142',
                    label: 'TJ',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                ]
              },
              {
                name: 'col1240',
                field: 'col1240',
                label: '12월',
                child: [
                  {
                    name: 'col1241',
                    field: 'col1241',
                    label: 'TOE',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                  {
                    name: 'col1242',
                    field: 'col1242',
                    label: 'TJ',
                    type: 'cost',
                    sortable: true,
                    style: 'width: 90px',
                  },
                ]
              },
            ],
          },
          {
            name: 'col555',
            field: 'col555',
            label: '합계',
            child: [
              {
                name: 'col5541',
                field: 'col5541',
                label: 'TOE',
                type: 'cost',
                sortable: true,
                style: 'width: 90px',
              },
              {
                name: 'col5542',
                field: 'col5542',
                label: 'TJ',
                type: 'cost',
                sortable: true,
                style: 'width: 90px',
              },
              {
                name: 'col5543',
                field: 'col5543',
                label: 'MJ',
                type: 'cost',
                sortable: true,
                style: 'width: 90px',
              },
            ]
          }
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        col1: null,
        col2: null,
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '80%',
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          col1: '사업장1', 
          col2: '설비팀', 
          col3: '건조공정', 
          col4: '직접배출', 
          col6: '공정연소시설 #021', 
          col7: 'LNG', 
          col8: '보조연료', 
          col9: 'km3', 
          col10: '2.123', 
          col30: '21', 
          col141: '3.2', 
          col142: '0.1', 
          col241: '0.4', 
          col242: '', 
          col341: '0.3', 
          col342: '', 
          col441: '0.6', 
          col442: '', 
          col541: '2.8', 
          col542: '', 
          col641: '2.6', 
          col642: '', 
          col741: '', 
          col742: '', 
          col841: '', 
          col842: '', 
          col941: '', 
          col942: '', 
          col1041: '', 
          col1042: '', 
          col1141: '', 
          col1142: '', 
          col1241: '', 
          col1242: '', 
          col5541: '', 
          col5542: '', 
          col5543: '', 
        },
        {
          col1: '사업장1', 
          col2: '설비팀', 
          col3: '건조공정', 
          col4: '직접배출', 
          col6: '공정연소시설 #045', 
          col7: 'LNG', 
          col8: '보조연료', 
          col9: 'km3', 
          col10: '2.123', 
          col30: '21', 
          col141: '1458.3', 
          col142: '61.1', 
          col241: '1291.26', 
          col242: '54.1', 
          col341: '1507.5', 
          col342: '63.1', 
          col441: '1499.8', 
          col442: '62.8', 
          col541: '1498.2', 
          col542: '62.7', 
          col641: '1287.1', 
          col642: '53.9', 
          col741: '', 
          col742: '', 
          col841: '', 
          col842: '', 
          col941: '', 
          col942: '', 
          col1041: '', 
          col1042: '', 
          col1141: '', 
          col1142: '', 
          col1241: '', 
          col1242: '', 
          col5541: '', 
          col5542: '', 
          col5543: '', 
        },
        {
          col1: '사업장1', 
          col2: '설비팀', 
          col3: '건조공정', 
          col4: '직접배출', 
          col6: '공정연소시설 #015', 
          col7: 'LNG', 
          col8: '보조연료', 
          col9: 'km3', 
          col10: '2.123', 
          col30: '21', 
          col141: '27.3', 
          col142: '1.1', 
          col241: '13.2', 
          col242: '2.4', 
          col341: '16.2', 
          col342: '1.9', 
          col441: '17.8', 
          col442: '2.0', 
          col541: '15.3', 
          col542: '2.2', 
          col641: '17.2', 
          col642: '1.7', 
          col741: '', 
          col742: '', 
          col841: '', 
          col842: '', 
          col941: '', 
          col942: '', 
          col1041: '', 
          col1042: '', 
          col1141: '', 
          col1142: '', 
          col1241: '', 
          col1242: '', 
          col5541: '', 
          col5542: '', 
          col5543: '', 
        },
      ];
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./baseinfo/dischargeFacDetail.vue"}`);
      this.popupOptions.title = '온실가스 배출시설 상세';
      this.popupOptions.param = {
        psiDocumentId: result,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
